import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout/index";
import { Link } from "gatsby";
import "./blogs.scss";

function Blogs() {
  const [blogs, setBlogs] = useState([]);

  const data = useStaticQuery(graphql`
    {
      allContentfulBlogs(sort: { fields: createdAt }) {
        edges {
          node {
            id
            title
            bannerImage {
              gatsbyImageData
            }
            blogContent {
              raw
            }
            authorName
            autherDesignation
            autherImage {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (data) {
      setBlogs(data.allContentfulBlogs.edges);
    }
  }, [data]);

  return (
    <Layout>
      <div className="blogContainer">
        <div className="blog">
          <div className="blog__header">
            <h2>Blogs</h2>
            <p>Because knowledge is meant for sharing.</p>
          </div>

          <div className="blog__body">
            {blogs && blogs.length > 0 && (
              <div className="blog__body__section-one">
                <div className="blog__body__section-one__img">
                  <img
                    src={
                      blogs[0]["node"].bannerImage.gatsbyImageData.images
                        .fallback.src
                    }
                    alt=""
                  />
                </div>

                <div className="blog__body__section-one__paragraph">
                  <Link to="/Blogs/Details?id=1">{blogs[0]["node"].title}</Link>
                  <p>
                    {
                      JSON.parse(blogs[0]["node"].blogContent.raw)["content"][0]
                        .content[0].value
                    }
                  </p>

                  <div className="author">
                    <div className="author__img">
                      <img
                        src={
                          blogs[0]["node"].autherImage.gatsbyImageData.images
                            .fallback.src
                        }
                        alt=""
                      />
                    </div>

                    <div className="author__details">
                      <h5>{blogs[0]["node"].authorName}</h5>
                      <p>{blogs[0]["node"].autherDesignation}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="blog__body__section">
              {blogs &&
                blogs.map(
                  (item, index) =>
                    index >= 1 && (
                      <div className="blog__body__section--two" key={index}>
                        <div className="blog__body__section--two__img">
                          <img
                            src={
                              item["node"].bannerImage.gatsbyImageData.images
                                .fallback.src
                            }
                            alt=""
                          />
                        </div>

                        <div className="blog__body__section--two__paragraph">
                          <Link to="/Blogs/Details?id=2">
                            {item["node"].title}
                          </Link>
                          <p>
                            {
                              JSON.parse(item["node"].blogContent.raw)[
                                "content"
                              ][0].content[0].value
                            }
                          </p>

                          <div className="author">
                            <div className="author__img">
                              <img
                                src={
                                  item["node"].autherImage.gatsbyImageData
                                    .images.fallback.src
                                }
                                alt=""
                              />
                            </div>

                            <div className="author__details">
                              <h5>{item["node"].authorName}</h5>
                              <p>{item["node"].autherDesignation}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Blogs;
